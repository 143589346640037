@import url('https://fonts.googleapis.com/css2?family=Gabarito:wght@400..900&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
.hero{
    display: flex;
    height: 100vh;
    width: 100vw;
    background-image: url(../../images/Home_full_16.webp);
    background-size: cover;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.3 );
  }

  section{
    scroll-snap-align: center;
  }

  .content{
    /* border: 3px black solid; */
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 0 0 0 auto;
    color: #efdeb5aa;
  }

  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000; /* Assicurati che la navbar sia sopra il resto del contenuto */
    /* background-color: rgba(0, 0, 0, 0.8); Sfondo semi-trasparente per separare visualmente la navbar */
    /* box-shadow: 0px 4px 2px -2px gray; Ombra per separare visualmente la navbar dal contenuto */
    padding: 5px 0; /* Puoi regolarevar il padding per gestire l'altezza della navbar */
  }
  
.body{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* border: 1px red solid; */
    padding-top: 30vh;
    flex: 1;
  }
.footer{
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 1px red solid; */

}

  .body h1{
    font-family: "Gabarito";
    font-optical-sizing: auto;
    font-size: 4vw;
    font-style: none;
  
  }
  .body p{
    white-space: pre-wrap; /* usato per prendere \n come new line da txt json */
    font-size: 2vw;
    font-family: "Gabarito", sans-serif;
    font-optical-sizing: auto;
  }

  .hero#pg2 {
     background-color: black;
     background-image: none;
  }


  @media only screen and (max-width: 1024px) {
    .body h1{
        font-size: 10vw;   
    }
    .body p{
        font-size: 2.5vw; 
    }

    .hero{
      background-image: url(../../images/home_mobile.webp);
      background-size: cover;
      box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.3 );
    }

  }

  @media only screen and (max-width: 696px) {
    .body{
      justify-content:flex-start;
      padding-top: 11vh;
       /* border: 1px red solid; */
    }

    .body h1{
        font-size: 10vw;   
    }
    .body p{
        font-size: 2.5vw; 
    }

    .hero{
      background-image: url(../../images/Home16_9.webp);
      background-size: cover;
      box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.3 );
    }
  }
