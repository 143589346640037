

:root {
  --dark-text: rgba(55, 52, 52, 0.701);
  --antracite: #2f2f2f;
  --marronescuro: #4b3621;
  --lilla: #dcd0ff;
  --grey:  #48464fc5;
}

* {  
  margin : 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  scroll-behavior: smooth;
  scrollbar-width: auto;
  scroll-snap-type: y mandatory;
}

/* Logo.css */

.logo {
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 10px;
}
.logo img {
  width: 30vw; /* Usa viewport width per dimensioni relative */
  max-width: 200px; /* Dimensione massima */
  height: auto; /* Mantiene le proporzioni */
  transition: all 0.3s ease !important;
}

.logo img:hover{
  transform: scale(1.2);
}
