.content_ab {
  width: 100vw;
  height: 100vh;
  background-color: var(--grey);
  padding-top: 60px;
  padding-bottom: 10px;
  color: #efdeb5aa;

}


.body_ab {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 70px);
  gap: 2em;
  padding-top: 1em;
  padding-left: 9vw;
  padding-right: 9vw;
  overflow-y: auto;
  justify-content: center ;

}

.box_ab {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  background-color: transparent;
}

.text_box {
  max-width: 40vw;
}
.text_box_title {
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.text_box_title h3{
  text-align: center;
}

.text_box p,
.text_box h1,
.text_box h2,
.text_box h3 {
  white-space: pre-wrap;
  font-size: 2vw;
  font-family: "Gabarito", sans-serif;
  text-align: center;
  border-radius: 10px;
  text-align: justify;

}

.text_box h1 {
  font-size: 45px;
}

.text_box p, ul{
  font-size: 21px;
}

.img_30 {
  width: 35vw;
  max-height: auto;
  object-fit: contain;
  border-radius: 20px;
}


@media (max-height: 700px) {
  .body_ab {
    overflow-y: auto;
  }
  .img_30{
    width: 33vw;
  }
}
