/* Container principale che occupa l'intero viewport */




.content_contatti {
  width: 100vw;
  height: 100vh;
  background-color: var(--grey);
  margin: 0;
  /* Rimuovi eventuali margini */
  box-sizing: border-box;
  /* Assicurati che il padding non influenzi le dimensioni */
  border: 1px var(--grey) solid;
  color: #efdeb5aa;
}

p,
h1,
h2,
h3 {
  white-space: pre-wrap;
}


.logo_contatti {
  width: 13vw;
  height: 20vh;
}


.icons {
  height: 60px;
  width: 60px;
  transition: all 0.3s ease !important;
}

.icons:hover {
  transform: scale(1.4);
  /* Ingrandisce l'immagine del 20% */
}

/* Container principale */
.contact-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  max-width: 100vw;
  margin: 60px auto 0px auto;
  padding: 40px;
  padding-bottom: 11px;
  background-color: transparent;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); */
  border-radius: 8px;
  height: calc(100vh - 70px);
}

/* Sezione delle informazioni di contatto */
.contact-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* background-color: #3c3838; */
  padding: 20px;
  border-radius: 8px;
  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5); */
  height: 55vh;
}

.contact-info strong {
  font-size: 1.5rem;
}

.contact-info a {
  font-size: 1.5rem;
  color: #efdeb5aa;
}

.contact-info h2 {
  font-size: 24px;

}

.contact-info p {
  margin: 0;
  font-size: 1.2rempx;

}

.social-icons {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

/* Sezione della mappa */
.map {
  /* background-color: #3c3838; */
  padding: 20px;
  border-radius: 8px;
  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5); */
  height: 65vh;
}

.map .carousel {
  height: 60vh;
  width: 40vw;
  /* border: 3px red solid; */

}

.content_card {
  width: 40vw;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content_card img {
  width: auto;
  height: 60vh;
  border-radius: 10px;

}

/* Modulo di contatto */
.contact-form {
  /* border: 3px red solid; */
  grid-column: span 2;
  background-color: #3c3838;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);
  max-height: 20vh;
}

.footer {
  /* border: 3px red solid; */
  display: flex;
  max-height: 30vh;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;

}

.footer p {
  /* border: 3px red solid; */
  font-size: 1.3rem;
  margin-bottom: 0rem;
  z-index: 1;
  position: relative;
}


.flex_form {
  /* border: 3px red solid; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 15vh;

}
.footer-link {
  color: inherit;
  /* Inherits the text color to maintain uniform appearance */
  text-decoration:underline;
  font-size: 20px;
}

@media (max-height: 1024px) {
  .icons {
    height: 40px;
    width: 40px;
    transition: none !important;
  }

}