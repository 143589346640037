/* Importa i font */
@import url('https://fonts.googleapis.com/css2?family=Gabarito:wght@400..900&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

/* Stili di base per l'intestazione */
header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    padding: 0 2rem;
    color: var(--textColor);
}

.border {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

/* Stili per i link della navbar */
nav a {
    margin: 0 2rem;
    color: var(--textColor);
    text-decoration: none;
    font-size: 25px;
    font-family: "Gabarito", sans-serif;
    font-weight: bold;
    padding-bottom: 10px;
    border-bottom: 3px solid transparent;
}

/* Stile attivo */
nav .active {
    color: white;
}

nav a:not(.active) {
    display: inline-block;
    transition: all 0.3s ease;
}

nav a:not(.active):hover {
    transform: scale(1.2);
}

/* Navbar desktop */
nav {
    flex: 4;
    display: flex;
    border-radius: 12px;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 8px 0 rgba(0, 0, 0, 0.0), 0 6px 20px 0 rgba(0, 0, 0, 0.0);
}

/* Pulsanti della navbar */
header .nav-btn {
    display: none;
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    color: var(--textColor);
    font-size: 1.8rem;
}

@media only screen and (max-width: 1024px) {

    /* Stili per fissare l'header in modalità mobile */
    header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 70px;
        padding: 0 2rem;
        color: var(--textColor);
        position: fixed;
        /* Fissa l'header in cima */
        top: 0;
        left: 0;
        right: 0;
        z-index: 1002;
        /* background-color: var(#2C3E50); */
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
        /* Ombra leggera */
    }

    /* Aggiungi un margine superiore al contenuto principale per evitare sovrapposizioni */
    .content {
        margin-top: 70px;
        /* Compensa l'altezza dell'header */
    }

    /* Mostra il burger menu */
    header .nav-btn {
        display: block;
    }

    /* Posizionamento e centratura verticale del pulsante burger */
    header .nav-btn--burger {
        position: relative;
        /* Rende il pulsante flessibile all'interno dell'header */
        left: 0;
        /* Allineato al bordo sinistro dell'header */
        z-index: 1003;
        /* Sopra l'header */
        font-size: 1.8rem;
        background: transparent;
        border: none;
        color: var(--textColor);
        display: flex;
        align-items: center;
        /* Centrato verticalmente */
        justify-content: center;
        height: 70px;
        /* Uguale all'altezza dell'header */
    }

    /* Menu di navigazione a tutto schermo */
    header nav {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        background-image: url(../images/DSC_2637.webp);
        background-size: cover;
        transition: transform 0.5s ease;
        transform: translateY(-100%);
        visibility: hidden;
        z-index: 1004;
    }

    /* Navbar con sfondo trasparente */
    .navbar--transparent {
        background-color: transparent;
        box-shadow: none;
        /* Rimuove l'ombra */
    }

    /* Navbar con sfondo solido */
    .navbar--solid {
        background-color: #5c5b63;
        /* Colore di sfondo grigio */
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    }


    /* Mostra la navigazione quando attiva */
    nav.responsive_nav {
        transform: translateY(0);
        visibility: visible;
    }

    /* Stile del pulsante di chiusura */
    nav .nav-btn--close {
        position: absolute;
        right: 2rem;
        top: 2rem;
        opacity: 1;
    }

    /* Stile dei link della navbar */
    nav a {
        font-size: 1.5rem;
        color: var(--textColor);
    }
}