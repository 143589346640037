.mobile {
    .content_ab {
        /* border: 3px black solid; */
        padding-left: 20px;
        height: auto;
        min-height: 100%;
        margin-top: 0;
        padding-top: 0;
    }

    .body_ab {
        display: flex;
        /* border: 3px red solid; */
        flex-direction: column;
        justify-content: space-around;
        /* justify-content: space-evenly; */
        height: calc(100vh);
        gap: 3em;
        padding-left: 1rem;
        padding-right: 2rem;
        padding-bottom: 60px;
        padding-top: 80px;
        height: auto;
        min-height: 100%;
    }

    .box_ab {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        /* border: 5px solid black;  */
        background-color: transparent;
        gap: 4rem;

    }

    .text_box {
        max-width: 100vw;
    }

    .text_box p,
    .text_box h1,
    .text_box h2,
    .text_box h3 {
        /* box-shadow: 4px 8px 0 rgba(0, 0, 0, 0.0), 0 6px 20px 0 rgba(0, 0, 0, 0.2); */
        border-radius: 10px;
        text-align: left;

    }

    .text_box h1 {
        font-size: 45px;
        text-align: center !important;
    }

    .text_box_title h3 {
        font-size: 1rem;
    }

    .text_box_title h1 {
        font-size: 2rem;
    }


    .text_box p, ul {
        font-size: 15px;
    }
    .content_contatti_sub_mobile {
            display: flex;
            flex-direction: column;
            justify-content: flex-start; /* Modificato per garantire l'allineamento in alto */
            align-items: center;
            gap: 20px;
            overflow-y: auto;
            background-color: var(--grey);
            box-sizing: border-box;
            color: #efdeb5aa;
            padding-top: 70px; /* Lasciare se necessario */
            padding-bottom: 2vh;
            height: 85vh; /* Assicurati che occupi tutta l'altezza della viewport */
    }

    .content_contatti_sub_mobile .contact-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100vw;
        padding: 2rem;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
        /* Subtle shadow effect */
        gap: 0.3rem;
    }

    .content_contatti_sub_mobile .information {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100vw;
        padding: 2rem;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
        gap: none;
        /* Subtle shadow effect */
    }

    .content_contatti_sub_mobile .footer {
        position: fixed;
        /* Fissa il footer nella posizione */
        bottom: 0;
        /* Posiziona il footer in basso */
        left: 0;
        /* Allinea a sinistra */
        right: 0;
        /* Allinea a destra */
        display: flex;
        width: 100vw;
        /* Larghezza al 100% della finestra */
        align-content: center;
        justify-content: center;
        height: 15vh;
        /* Altezza del footer */
        background-color: #3c3838;
        /* Colore di sfondo */
        margin-bottom: 0;
        /* Rimuovi margine inferiore */
        z-index: 1001;
        /* Assicurati che sia sopra ad altri contenuti */
    }


    .content_contatti_sub_mobile .footer p {
        font-size: 0.7rem;

    }

    .content_contatti_sub_mobile .logo_contatti {
        width: 35vw;
        height: 20vh;
    }

    .img_30 {
        width: 80vw;
        height: auto;
        object-fit: contain;
        border-radius: 20px;
    }


    .carousel {
        /* border: 3px red solid; */
        height: 40vh;
        width: 80vw;

    }

    .content_card {
        width: 80vw;
        height: 40vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .content_card img {
        width: auto;
        height: 40vh;
        border-radius: 10px;

    }

    .social-icons {
        display: flex;
        gap: 20px;
        margin-top: 10px;
        z-index: 1001;
        margin-bottom: 10vh; 
        justify-content: center;
        /* Puoi anche aggiungere altre proprietà per l'allineamento */
    }
    

    .phone-link,
    .footer-link {
        color: inherit;
        /* Inherits the text color to maintain uniform appearance */
        text-decoration:underline;
        font-size: 0.85rem;
    }

    .phone-link:hover,
    .footer-link:hover {
        text-decoration: underline;
        /* Adds underline on hover for interactivity */
    }

    footer.footer p {
        margin: 5px 0;
        font-size: 0.85rem;
    }
    .information a {
        font-size: 1.5rem;
        color: #efdeb5aa;
    }

}